import { z } from "zod";

import { undefinable } from "../../zodHelpers";

export const BaseProductEventValidator = z.object({
  /**
   * The SKU of the product
   *
   * @example "705990104"
   */
  id: undefinable(z.string()).default("unknown"),
  /**
   * The name of the product
   *
   * @example "Hansgrohe Focus 240 køkkenarmatur"
   */
  name: z.string(),
  /**
   * The brand name of the product
   *
   * @example "Hansgrohe"
   */
  brand: undefinable(z.string()),
  /**
   * Product category of the item
   *
   * @example "Køkken/Køkkenvasken/Køkkenarmaturer"
   */
  category: undefinable(z.string()),
  /**
   * The variant (color or format) of the main product
   *
   * @example "Krom"
   */
  variant: undefinable(z.string()),
  /**
   * Product unitary price
   *
   * @example 1529.50
   */
  price: z.coerce.number(),
  /**
   * The price before any discounts
   */
  normalPrice: undefinable(z.coerce.number()),
  /**
   * URL of the product
   */
  url: z.string(),
  /**
   * URL of the product image
   */
  image: undefinable(z.string()),
});

export const AlgoliaProductEventValidator = z.object({
  /**
   * Id of the current algolia query
   */
  queryId: z.string(),
  /**
   * Position of product in the results
   *
   * @example 8
   */
  position: z.number(),
  /**
   * Name of the index used when generating the event
   *
   * @example "DK_products"
   */
  index: z.string(),
  /**
   * The algolia object id
   *
   * This is usually the aonumber, but linked products might include a prefix
   */
  objectId: z.string(),
});

export type IBaseProductEvent = z.infer<typeof BaseProductEventValidator>;
export type IAlgoliaProductEvent = z.infer<typeof AlgoliaProductEventValidator>;
