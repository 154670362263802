import { t } from "ttag";

import { AnnotatedHTTPError } from "../shared/http/HTTP";
import { IModalContext } from "../shared/modules/Modal/ModalContext";
import { Analytics } from "../shared/utils/analytics/AnalyticsEvents";
import { IAddToBasketEvent } from "../shared/utils/analytics/events/addToBasket";
import { buildCloudinaryImage } from "../shared/utils/cloudinary/image";
import { queryClient } from "../shared/utils/queryClient";
import { notNullish } from "../shared/utils/types/notNull";
import { CART_QUERY, useCartMutations } from "./hooks/useCart";
import { fetchCartItemMetadata, ICartItemUpdate } from "./http/cart.http";
import { ICart, ICartAddMetadata, ICartUpdateMetadata } from "./http/dto/Cart.dto";
import { cartItemToAnalytics } from "./utils";

export type IAddProduct = {
  /**
   * The product to add.
   */
  product: ICartAddMetadata;
  /**
   * The quantity of the product to add.
   */
  quantity: number;
  /**
   * The source of the product being added.
   */
  source: IAddToBasketEvent["source"];
};

type IAddLegacyProducts = { aonumber: string; quantity: number; source: IAddToBasketEvent["source"] }[];

export class CartController {
  constructor(
    private modal: IModalContext,
    public mutator: ReturnType<typeof useCartMutations>,
    private powerstep: (productIds: string[]) => Promise<void>,
  ) {}

  /**
   * Retrieves the current cart, downloading it if necessary.
   *
   * @returns - A promise that resolves to the current cart.
   */
  #getCart = (): Promise<ICart> => {
    return queryClient.fetchQuery(CART_QUERY);
  };

  /**
   * Trigger analytics for a product being added or updated.
   */
  #analytics = ({
    update: { product, source, quantity },
    cart,
    oldCart,
  }: {
    update: IAddProduct;
    cart: ICart;
    oldCart?: ICart;
  }) => {
    let line = cart.items.find((item) => item.product_id === product.product_id);
    if (!line && oldCart) {
      line = oldCart.items.find((item) => item.product_id === product.product_id);
    }

    // Only track analytics for products with an ao_number
    if (!line?.preview.ao_number) {
      return;
    }

    if (quantity < 0) {
      Analytics.event("action", "removeFromBasket").dispatchEvent({
        ...cartItemToAnalytics(line),
        quantity: -quantity,
        cart: cart,
      });
    } else {
      Analytics.event("action", "addToBasket").dispatchEvent({
        ...cartItemToAnalytics(line),
        priceDkk: line.analytics.price_dkk,
        quantity: quantity,
        source: source,
        cart: cart,
      });
    }

    if (window.raptor) {
      window.raptor.trackEvent(
        "basket",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        cart.items.map((item) => item.analytics.raptor_id).filter(notNullish),
        cart.secure_id,
      );
    }
  };

  /**
   * Error handler function that wraps an async operation and handles any errors that occur during its execution.
   *
   * @param operation - The basket operation to be executed
   * @returns - A promise that resolves with either the successful result of the operation or an indication of failure
   */
  #errorHandler = async <T>(operation: () => Promise<T>): Promise<{ success: true; data: T } | { success: false }> => {
    try {
      const data = await operation();
      return { success: true, data: data };
    } catch (error) {
      const errorMessage =
        error instanceof AnnotatedHTTPError ? error.message : `Ukendt fejl ved tilføjelse af produkter til kurv`;
      await this.modal.openError({ content: errorMessage });
      return { success: false };
    }
  };

  /**
   * Retrieves an existing cart item with the given product ID.
   *
   * @param productId - The ID of the product.
   */
  #getExistingCartItem = async (productId: string) => {
    const cart = await this.#getCart();
    return cart.items.find((item) => item.product_id === productId);
  };

  /**
   * Resolves constraints when adding a product to the cart.
   *
   * @param product - The product being added.
   * @param quantity - The quantity of the product being added.
   * @param source - The source of the product being added.
   */
  #resolveConstraints = async ({ product, quantity, source }: IAddProduct): Promise<ICartItemUpdate> => {
    let note = undefined;
    if (product.constraints.require_note) {
      const existingLine = await this.#getExistingCartItem(product.product_id);
      if (!existingLine) {
        note = await this.modal.openPrompt({
          title: t`Notat`,
          content:
            typeof product.constraints.require_note === "object"
              ? product.constraints.require_note.text
              : t`Notat til bestilling skal angives her`,
          label: t`Notat`,
          confirmButtonText: t`Godkend`,
          cancelButtonText: t`Luk`,
        });
      }
    }

    return { productId: product.product_id, quantity, source, note };
  };

  /**
   * Adds a product to the cart.
   *
   * @param products - The products to add to the cart.
   * @param options - Options for the add operation.
   */
  addProducts = async (products: IAddProduct[], options?: { skipPowerstep: boolean }) => {
    const skipPowerstep = options?.skipPowerstep ?? false;

    const resolvedProducts = await Promise.all(products.map(this.#resolveConstraints));
    const add = () => {
      return this.mutator.add.mutateAsync(resolvedProducts);
    };
    const result = await this.#errorHandler(add);
    if (result.success && !skipPowerstep) {
      products.map((update) => {
        this.#analytics({ update, cart: result.data });
      });
      await this.powerstep(resolvedProducts.map((product) => product.productId));
    }
    return result;
  };

  /**
   * Adds a product to the cart via a legacy aonumber.
   *
   * @param products - The products to add to the cart.
   */
  addLegacyProducts = async (products: IAddLegacyProducts) => {
    const metaDataRequests = products.map(async ({ aonumber, ...rest }) => ({
      product: await fetchCartItemMetadata(aonumber),
      ...rest,
    }));
    return this.addProducts(await Promise.all(metaDataRequests));
  };

  /**
   * Updates an item in the shopping cart.
   * @param updates - The update metadata for the cart item.
   * @param source - The source of the update.
   * @param delta - The change in quantity.
   */
  updateProduct = async (updates: ICartUpdateMetadata, source: IAddToBasketEvent["source"], delta: number) => {
    const update = async () => {
      let newQuantity = undefined;
      if (this.mutator.update.variables?.lineId === updates.line_id) {
        newQuantity = this.mutator.update.variables?.quantity + delta;
      }
      if (newQuantity === undefined) {
        const cart = await this.#getCart();
        const line = cart.items.find((item) => item.line_id === updates.line_id);
        if (!line) {
          throw new Error(`Line not found`);
        }
        newQuantity = line.quantity + delta;
      }
      return this.mutator.update.mutateAsync({ lineId: updates.line_id, quantity: newQuantity, source });
    };

    const result = await this.#errorHandler(update);
    if (result.success) {
      this.#analytics({ update: { product: updates, source: source, quantity: delta }, cart: result.data });
    }
  };

  /**
   * Removes a product from the cart.
   *
   * @param updates - The update metadata for the cart item.
   * @param source - The source of the cart update.
   * @returns - A Promise that resolves when the product is removed from the cart.
   */
  removeProduct = async (updates: ICartUpdateMetadata, source: IAddToBasketEvent["source"]) => {
    const originalBasket = await this.#getCart();
    const originalQuantity = originalBasket.items.find((item) => item.line_id === updates.line_id)?.quantity;

    const remove = () => {
      return this.mutator.remove.mutateAsync({ lineId: updates.line_id, source });
    };
    const result = await this.#errorHandler(remove);
    if (result.success && originalQuantity !== undefined) {
      this.#analytics({
        update: { product: updates, source: source, quantity: -originalQuantity },
        cart: result.data,
        oldCart: originalBasket,
      });
    }
    return result;
  };

  /**
   * Applies a rebate code to the cart.
   *
   * @param rebateCode - The rebate code to apply.
   */
  applyRebateCode = (rebateCode: string | undefined) => {
    return this.mutator.rebateCode.mutateAsync({ rebateCode });
  };

  /**
   * Empties the cart.
   */
  empty = async () => {
    const originalBasket = await this.#getCart();
    const apply = () => {
      return this.mutator.empty.mutateAsync();
    };

    const result = await this.#errorHandler(apply);

    if (result.success) {
      originalBasket.items.map((item) => {
        this.#analytics({
          update: { product: item, source: "basket", quantity: -item.quantity },
          cart: result.data,
          oldCart: originalBasket,
        });
      });
    }

    return result;
  };

  /**
   * Returns the optimistic product count for the cart.
   */
  optimisticProductCount = () => {
    if (this.mutator.add.error) {
      return {};
    }
    return {
      [this.mutator.update.variables?.lineId ?? Symbol("none")]: this.mutator.update.variables?.quantity,
    };
  };

  /**
   * Returns the optimistic product removal for the cart.
   */
  optimisticRemoveProduct = () => {
    if (this.mutator.remove.error) {
      return {};
    }
    return {
      [this.mutator.remove.variables?.lineId ?? Symbol("none")]: true,
    };
  };

  /**
   * Returns the optimistic price for the cart.
   */
  optimisticPrice = () => {
    const data = queryClient.getQueryData<ICart>(CART_QUERY.queryKey);
    if (!data) {
      return undefined;
    }

    if (!this.mutator.update?.variables && !this.mutator.remove?.variables) {
      return undefined;
    }

    if (this.mutator.update?.error || this.mutator.remove?.error) {
      return undefined;
    }

    return data.items.reduce((acc, item) => {
      if (item.line_id === this.mutator.update?.variables?.lineId) {
        return acc + item.price * this.mutator.update.variables.quantity;
      }
      if (item.line_id === this.mutator.remove?.variables?.lineId) {
        return acc;
      }
      return acc + item.price * item.quantity;
    }, 0);
  };
}
