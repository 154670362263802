import clsx from "clsx";
import React, { FunctionComponent } from "react";

import styles from "./ModalContent.style.scss";

interface IModalContent extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

const ModalContent: FunctionComponent<React.PropsWithChildren<IModalContent>> = ({ children, className, ...props }) => {
  return (
    <div className={clsx(className, styles.content)} {...props}>
      {children}
    </div>
  );
};

export const _ModalContent = ModalContent;
