import { ICart } from "../../../../cart/http/dto/Cart.dto";
import { buildCloudinaryImage } from "../../cloudinary/image";
import { Analytics } from "../AnalyticsEvents";

const cartToKlaviyo = (cart: ICart) => {
  return cart.items.map((product) => ({
    ProductID: product.preview.ao_number,
    SKU: product.preview.ao_number,
    ProductName: product.preview.name,
    Quantity: product.quantity,
    ItemPrice: product.price,
    RowTotal: product.price * product.quantity,
    ProductURL: product.preview.url,
    ImageURL: product.preview.image
      ? buildCloudinaryImage(product.preview.image, {
          transformations: {
            width: 200,
            height: 200,
            cropMode: "pad",
            aspectRatio: 1,
            backgroundColor: "white",
            format: "jpg",
          },
        })
      : undefined,
    ProductCategories: product.analytics.category?.split("/") ?? [],
  }));
};

const absoluteUrl = (url: string) => {
  return new URL(url, window.location.origin).toString();
};

export const initializeKlaviyo = () => {
  const basketUrl = absoluteUrl(`/basket/?userbasket=${window.BASKET_ID}`);

  Analytics.event("impression", "productDetail").addEventListener((event) => {
    if (!window.klaviyo) {
      return;
    }

    window.klaviyo.push([
      "track",
      "Viewed Product",
      {
        ProductName: event.name,
        ProductID: event.id,
        SKU: event.id,
        Categories: event.category?.split("/") ?? [],
        ImageURL: event.image,
        URL: absoluteUrl(event.url),
        Brand: event.brand,
        Price: event.price,
        CompareAtPrice: event.normalPrice,
      },
    ]);
    window.klaviyo.push([
      "trackViewedItem",
      {
        Title: event.name,
        ItemID: event.id,
        Categories: event.category?.split("/") ?? [],
        ImageUrl: event.image,
        Url: absoluteUrl(event.url),
        Metadata: {
          Brand: event.brand,
          Price: event.price,
          CompareAtPrice: event.normalPrice,
        },
      },
    ]);
  });

  Analytics.event("action", "addToBasket").addEventListener((event) => {
    if (!window.klaviyo) {
      return;
    }

    window.klaviyo.push([
      "track",
      "Added to Cart",
      {
        $value: event.cart.price,
        AddedItemProductName: event.name,
        AddedItemProductID: event.id,
        AddedItemSKU: event.id,
        AddedItemCategories: event.category?.split("/") ?? [],
        AddedItemImageURL: event.image,
        AddedItemURL: absoluteUrl(event.url),
        AddedItemPrice: event.price,
        AddedItemQuantity: event.quantity,
        ItemNames: event.cart.items.map((product) => product.preview.name),
        CheckoutURL: basketUrl,
        Items: cartToKlaviyo(event.cart),
      },
    ]);
  });

  Analytics.event("action", "checkout").addEventListener((event) => {
    if (event.step !== 2 || !window.klaviyo) {
      return;
    }

    const totalPrice = event.products.reduce((acc, product) => acc + product.price * product.quantity, 0);
    const unixTime = Math.floor(Date.now() / 1000);
    window.klaviyo.push([
      "track",
      "Started Checkout",
      {
        $event_id: `${window.BASKET_ID}_${unixTime}`,
        $value: totalPrice,
        ItemNames: event.products.map((p) => p.name),
        CheckoutURL: basketUrl,
        Categories: event.products.flatMap((p) => p.category?.split("/") ?? []),
        Items: event.products.map((product) => ({
          ProductID: product.id,
          SKU: product.id,
          ProductName: product.name,
          Quantity: product.quantity,
          ItemPrice: product.price,
          RowTotal: product.price * product.quantity,
          ProductURL: absoluteUrl(product.url),
          ImageURL: product.image,
          ProductCategories: product.category?.split("/") ?? [],
          CompareAtPrice: product.normalPrice,
        })),
      },
    ]);
  });
};
