import { z } from "zod";

import { CloudinaryFileValidator } from "../../../shared/http/dto/Cloudinary.dto";
import { undefinable } from "../../../shared/utils/zodHelpers";

export const CartAddMetadataValidator = z.object({
  product_id: z.coerce.string(),
  constraints: z.object({
    max: undefinable(z.number()),
    require_note: undefinable(z.union([z.boolean(), z.object({ text: z.string() })])),
    locked: z.boolean(),
  }),
  price: z.coerce.number(),
});

export const CartUpdateMetadataValidator = CartAddMetadataValidator.extend({
  line_id: z.coerce.string(),
});

const CartItemFooterTypeValidator = z.union([z.literal("info"), z.literal("warning"), z.literal("campaign")]);

export const CartItemValidator = CartUpdateMetadataValidator.extend({
  quantity: z.number(),
  normal_price: undefinable(z.coerce.number()),
  preview: z.object({
    image: undefinable(CloudinaryFileValidator),
    name: z.string(),
    variant_name: undefinable(z.string()),
    ao_number: undefinable(z.string()),
    in_stock: z.boolean(),
    delivery_time: undefinable(z.string()),
    url: z.string(),
    notifications: z.array(
      z.object({
        message: z.string(),
        type: CartItemFooterTypeValidator,
      }),
    ),
    discount_message: undefinable(z.string()),
  }),
  analytics: z.object({
    category: undefinable(z.string()),
    brand: undefinable(z.string()),
    raptor_id: undefinable(z.string()),
    price_dkk: z.coerce.number(),
  }),
});

export const CartNonItemValidator = z.object({
  type: z.union([z.literal("fee"), z.literal("discount")]),
  price: z.union([z.coerce.number(), z.string()]),
  text: z.string(),
});

export const CartPromotionValidator = z.object({
  text: z.string(),
  minimum_value: z.coerce.number(),
  current_value: z.coerce.number(),
});

export const DeliveryCutoffValidator = z.object({
  time: z.union([z.coerce.date(), z.string()]), // This can be a string if its hydrated from localstorage
  type: z.union([z.literal("delivery_tomorrow"), z.literal("shipping_today")]),
});

export const CartValidator = z.object({
  price: z.coerce.number(),
  items: z.array(CartItemValidator),
  non_items: z.array(CartNonItemValidator),
  promotions: z.array(CartPromotionValidator),
  delivery_cutoff: undefinable(DeliveryCutoffValidator),
  rebate_code: undefinable(z.string()),
  rebate_code_message: undefinable(z.array(z.string())),
  is_offer: z.boolean(),
  logged_in: z.boolean(),
  secure_id: z.string(),
  estimated_shipping_fee: z.union([z.coerce.number(), z.string()]),
});

export const CartErrorValidator = z.object({});

export type ICart = z.infer<typeof CartValidator>;
export type ICartAddMetadata = z.infer<typeof CartAddMetadataValidator>;
export type ICartUpdateMetadata = z.infer<typeof CartUpdateMetadataValidator>;
export type ICartItem = z.infer<typeof CartItemValidator>;
export type ICartNonItem = z.infer<typeof CartNonItemValidator>;
export type ICartItemFooterType = z.infer<typeof CartItemFooterTypeValidator>;
