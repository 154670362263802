import clsx from "clsx";
import IconCheck from "icons/checkmark.svg";
import React, { FunctionComponent } from "react";

import { Stars } from "../../shared/components/Stars";
import { useConfiguration } from "../../shared/modules/Configuration/ConfigurationContext";
import styles from "./HeaderUSP.style.scss";

export const HeaderUSP: FunctionComponent = (props) => {
  const { USP, SPLIT_TESTS } = useConfiguration();

  if (!SPLIT_TESTS?.usp_header) {
    return null;
  }

  if (!USP || USP.length === 0) {
    return null;
  }

  return (
    <div className={styles.container}>
      {USP.map((usp, index) => (
        <span className={styles.usp} key={index}>
          {usp.stars ? (
            <>
              <Stars score={usp.stars} classNames={{ star: styles.star }} />
              <IconCheck className={clsx(styles.starMobile, styles.icon)} />
            </>
          ) : (
            <IconCheck className={styles.icon} />
          )}
          {usp.label}
        </span>
      ))}
    </div>
  );
};
