import clsx from "clsx";
import React, { FunctionComponent } from "react";
import { usePreventScroll } from "react-aria";

import styles from "../Modal.style.scss";

interface IModalOverlayProps {
  onClose: () => void;
  classNames: string;
}

export const ModalOverlay: FunctionComponent<React.PropsWithChildren<IModalOverlayProps>> = ({
  children,
  onClose,
  classNames,
}) => {
  const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };
  usePreventScroll();

  return (
    <div role="close-outside" onClick={handleOverlayClick} className={clsx(styles.overlay, classNames)}>
      {children}
    </div>
  );
};
