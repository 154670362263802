import { isHTMLElement } from "../../shared/utils/types/isHTMLElement";
import { subscribe } from "../modules/newsletter";

(() => {
  document.querySelector(".js-subscribe-email")?.addEventListener("click", (event) => {
    if (!isHTMLElement(event.currentTarget)) {
      return;
    }
    subscribe(
      event.currentTarget.dataset.name ?? "",
      event.currentTarget.dataset.email ?? "",
      event.currentTarget.dataset.source ?? "",
      event.currentTarget.dataset.phone ?? "",
      "subscribe",
      (resp) => {
        alert(resp.message);
      },
    );
  });
})();
