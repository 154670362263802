import clsx from "clsx";
import IconHalfStar from "icons/half-star.svg";
import IconStar from "icons/star.svg";
import React, { FunctionComponent, useState } from "react";

import styles from "./Stars.style.scss";

interface IStarsProps {
  score: number | undefined;
  onClick?: (score: number) => void;
  className?: string;
  classNames?: {
    star?: string;
    activeStar?: string;
  };
}

export const Stars: FunctionComponent<IStarsProps> = ({ score, className, classNames, onClick }) => {
  const [hover, setHover] = useState<number | undefined>(undefined);

  return (
    <div className={clsx(styles.container, className)} onMouseLeave={() => setHover(undefined)}>
      {[1, 2, 3, 4, 5].map((value) => {
        const activeScore = onClick ? hover ?? score : score;
        const onHover = () => setHover(value);
        const click = () => onClick?.(value);
        if ((activeScore ?? 0) >= value) {
          return (
            <IconStar
              key={value}
              onMouseOver={onHover}
              onClick={click}
              className={clsx(
                styles.star,
                styles.activeStar,
                onClick && styles.clickable,
                classNames?.star,
                classNames?.activeStar,
              )}
            />
          );
        } else if ((activeScore ?? 0) >= value - 0.5) {
          return (
            <IconHalfStar
              key={value}
              onMouseOver={onHover}
              onClick={click}
              className={clsx(
                styles.star,
                styles.activeStar,
                onClick && styles.clickable,
                classNames?.star,
                classNames?.activeStar,
              )}
            />
          );
        } else {
          return (
            <IconStar
              key={value}
              className={clsx(styles.star, onClick && styles.clickable, classNames?.star)}
              onMouseOver={onHover}
              onClick={click}
            />
          );
        }
      })}
    </div>
  );
};
