import clsx from "clsx";
import React, { FunctionComponent, Suspense, useCallback, useState } from "react";

import {
  IModalContext,
  IOpenAlertProps,
  IOpenConfirmProps,
  IOpenErrorProps,
  IOpenModalProps,
  IOpenPromptProps,
  ModalProvider,
} from "./ModalContext";

const DefaultModal = React.lazy(() => import("./implementations/DefaultModal"));
const AlertModal = React.lazy(() => import("./implementations/AlertModal"));
const ErrorModal = React.lazy(() => import("./implementations/ErrorModal"));
const PromptModal = React.lazy(() => import("./implementations/PromptModal"));
const ConfirmModal = React.lazy(() => import("./implementations/ConfirmModal"));

export const preloadModalComponents = async () => {
  await Promise.all([DefaultModal, AlertModal, ErrorModal, PromptModal, ConfirmModal]);
};

/**
 * Context provider manager for modal components
 * used for displaying various types of modals such as alerts, errors, confirms, and prompts.
 */
export const ModalManager: FunctionComponent<React.PropsWithChildren> = ({ children }) => {
  const [currentModal, setCurrentModal] = useState<React.ReactNode | undefined>(undefined);

  const resolveRef = React.useRef<(() => void) | undefined>();

  const closeModal = useCallback(() => {
    setCurrentModal(undefined);
    resolveRef.current?.();
  }, []);

  const alert = useCallback(
    (props: IOpenAlertProps) => {
      return new Promise<undefined>((resolve) => {
        resolveRef.current = () => resolve(undefined);
        const handleClose = () => {
          closeModal();
          resolve(undefined);
        };
        setCurrentModal(
          <AlertModal {...props} className={props.className} zIndex={1010} onClose={handleClose}>
            {props.content}
          </AlertModal>,
        );
      });
    },
    [closeModal],
  );

  const modal = useCallback(
    (props: IOpenModalProps) => {
      return new Promise<undefined>((resolve) => {
        resolveRef.current = () => resolve(undefined);
        const handleClose = () => {
          closeModal();
          resolve(undefined);
        };
        setCurrentModal(
          <DefaultModal
            title={props.title ?? ""}
            className={props.className}
            zIndex={1010}
            onClose={handleClose}
            size={props.size}
          >
            {props.content}
          </DefaultModal>,
        );
      });
    },
    [closeModal],
  );

  const error = useCallback(
    (props: IOpenErrorProps) => {
      return new Promise<undefined>((resolve) => {
        resolveRef.current = () => resolve(undefined);
        const handleClose = () => {
          closeModal();
          resolve(undefined);
        };
        setCurrentModal(
          <ErrorModal {...props} className={props.className} zIndex={1010} onClose={handleClose}>
            {props.content}
          </ErrorModal>,
        );
      });
    },
    [closeModal],
  );

  const prompt = useCallback(
    (props: IOpenPromptProps) => {
      return new Promise<string | undefined>((resolve) => {
        resolveRef.current = () => resolve(undefined);
        const confirm = (value: string) => {
          resolve(value);
          closeModal();
        };
        const handleClose = () => {
          resolve(undefined);
          closeModal();
        };
        setCurrentModal(
          <PromptModal {...props} className={props.className} zIndex={1010} onClose={handleClose} onConfirm={confirm}>
            {props.content}
          </PromptModal>,
        );
      });
    },
    [closeModal],
  );

  const confirm = useCallback(
    (props: IOpenConfirmProps) => {
      return new Promise<boolean | undefined>((resolve) => {
        resolveRef.current = () => resolve(undefined);
        const confirm = () => {
          resolve(true);
          closeModal();
        };
        const cancel = () => {
          resolve(false);
          closeModal();
        };
        const handleClose = () => {
          resolve(undefined);
          closeModal();
        };
        setCurrentModal(
          <ConfirmModal
            {...props}
            className={props.className}
            zIndex={1010}
            onClose={handleClose}
            onCancel={cancel}
            onConfirm={confirm}
          >
            {props.content}
          </ConfirmModal>,
        );
      });
    },
    [closeModal],
  );

  const context: IModalContext = {
    closeModal,
    openAlert: alert,
    openError: error,
    openConfirm: confirm,
    openPrompt: prompt,
    openModal: modal,
  };

  return (
    <ModalProvider value={context}>
      {children}
      {currentModal && <Suspense fallback={null}>{currentModal}</Suspense>}
    </ModalProvider>
  );
};
