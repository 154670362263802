import { z } from "zod";

import { ShowroomsValidator } from "../../../productpage/http/dto/Showroom.dto";
import { undefinable } from "../../utils/zodHelpers";

export const ConfigurationValidator = z.object({
  SHOP_NAME: z.string(),
  DOMAIN_SHORT: z.string(),
  FLEX_URL: z.string(),
  CONTACT_URL: z.string(),
  FREIGHT_MODAL_CMS_PAGE: undefinable(z.string()),
  CLIMATE_FRIENDLY_CMS_PAGE: undefinable(z.string()),
  CLOUDINARY_CLOUD_NAME: z.string(),
  SHOP_CURRENCY: z.string(),
  WALLEY_FRONTEND_URL: z.string(),
  DONE_ACTIVATED: undefinable(z.boolean()),
  WALLEY_ACTIVATED: undefinable(z.boolean()),
  SPLIT_TESTS: z.record(z.string(), z.union([z.string(), z.boolean()])),
  CMS_RETURN_TERMS_URL: z.string(),
  PRIVACY_POLICY_URL: z.string(),
  USP: z.array(z.object({ label: z.string(), stars: undefinable(z.number()) })),
  CHECKOUT_NEWSLETTER: undefinable(
    z.object({
      HEADLINE: undefinable(z.string()),
      BODY: undefinable(z.string()),
      DISCLAIMER: undefinable(z.string()),
    }),
  ),
  REVIEW_WIDGET: z.object({
    TRUSTPILOT_URL: undefinable(z.string()),
    GOOGLE_REVIEW_URL: undefinable(z.string()),
  }),
  FOMO: z.object({
    last_chance: z.boolean(),
    few_left: z.boolean(),
    most_sold: z.boolean(),
  }),
  SHOWROOM: undefinable(ShowroomsValidator),
  DAY_TO_DAY_GUARANTEE: undefinable(z.boolean()),
  GUIDANCE_ASSISTANT_IMAGE: z.string(),
  SHOW_CUSTOMER_CENTER_INFO_PRODUCT_PAGE: undefinable(z.boolean()),
  EXTRAORDINARY_SAVINGS: undefinable(
    z.object({
      flat: undefinable(z.number()),
      pct: undefinable(z.number()),
      text: z.string(),
    }),
  ),
  AUTH_ENABLED: z.boolean(),
});

export type IConfiguration = z.infer<typeof ConfigurationValidator>;
