import clsx from "clsx";
import CustomerServiceIcon from "icons/headset.svg";
import LoginIcon from "icons/profile.svg";
import MagnifyingGlass from "icons/search.svg";
import React, { FunctionComponent, Suspense, useMemo, useState } from "react";
import ShopLogo from "svg/logo.svg?url";
import { t } from "ttag";

import { useCartAmountQuery, useCartQuery } from "../cart/hooks/useCart";
import { BrandCartIcon } from "../shared/components/BrandCartIcon";
import { Container } from "../shared/components/Container";
import { useConfiguration } from "../shared/modules/Configuration/ConfigurationContext";
import { Drawer } from "../shared/modules/Drawer/Drawer";
import { retry } from "../shared/utils/retry";
import useSSR from "../shared/utils/useSSR";
import { HeaderIcon } from "./components/HeaderIcon";
import { HeaderUSP } from "./components/HeaderUSP";
import { MegaMenu } from "./components/MegaMenu";
import { SearchInput } from "./components/SearchInput";
import { IHeader } from "./dto/Header.dto";
import styles from "./Header.style.scss";
import { useIsScrolled } from "./useIsScrolled";

const MobileMegamenu = React.lazy(() => retry(() => import("./modules/mobile-megemenu/MobileMegamenu")));

export const Header: FunctionComponent<IHeader> = ({ entries }) => {
  const { SPLIT_TESTS } = useConfiguration();
  const isScrolled = useIsScrolled({ threshold: SPLIT_TESTS.usp_header ? 45 : 20 });
  const isSSR = useSSR();

  const { data } = useCartQuery({ enabled: !isSSR });
  const { data: cartAmount } = useCartAmountQuery({ enabled: !isSSR });
  const { AUTH_ENABLED } = useConfiguration();
  const [loadMenu, setLoadMenu] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const authIcon = useMemo(() => {
    if (!AUTH_ENABLED) {
      return null;
    }
    if (!isSSR && data?.logged_in) {
      return <HeaderIcon className={styles.hideMobile} icon={LoginIcon} href={"/account/"} text={t`Min konto`} />;
    }

    return <HeaderIcon className={styles.hideMobile} icon={LoginIcon} href={"/account/login/"} text={t`Log ind`} />;
  }, [AUTH_ENABLED, data?.logged_in, isSSR]);

  return (
    <>
      <HeaderUSP />
      <header data-a-type={"header"} className={clsx(styles.container, isScrolled && styles.sticky)} id={"navigation"}>
        <Drawer isOpen={menuOpen} onOpenChange={setMenuOpen}>
          <Suspense fallback={null}>{loadMenu && <MobileMegamenu entries={entries} />}</Suspense>
        </Drawer>
        <div className={styles.wrapper}>
          <Container className={styles.header}>
            <div
              className={styles.headerMobileMenu}
              onClick={() => {
                setMenuOpen(true);
                setLoadMenu(true);
              }}
            >
              <div />
              <div />
              <div />
            </div>
            <a href={"/"} className={styles.headerLogo}>
              <img src={ShopLogo} className={styles.logo} alt="Brand logo" />
            </a>
            <SearchInput isScrolled={isScrolled} className={styles.headerSearch} />
            <div className={styles.headerButtons}>
              <HeaderIcon
                className={styles.hideMobile}
                icon={CustomerServiceIcon}
                href={"/contact"}
                text={t`Kundeservice`}
              />
              {authIcon}
              <HeaderIcon
                counter={isSSR ? undefined : cartAmount}
                icon={BrandCartIcon}
                href={"/basket/"}
                text={t`Indkøbskurv`}
              />
            </div>
          </Container>
        </div>
        <MegaMenu entries={entries} />
      </header>
    </>
  );
};
